import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, EffectCreative, Autoplay } from "swiper";

import Container from "./container";
import AnimationWrapper from "./animationWrapper";
import TextSection from "./textSection";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-creative';
import 'swiper/scss/autoplay';

import "./swiperVideoGallery.css"

const SwiperGallery = ({ data }) => {

  // const loadGallery = data.realisation.frontmatter.carousel_gallery.images.some(
  //   image => image.status === 'active'
  // )

  // const getYouTubeVideoId = (url) => {
  //   // Define a regular expression pattern for matching YouTube URLs
  //   const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  //   // Use the pattern to extract the video ID
  //   const match = url.match(pattern);
  //   // Check if a match is found and return the video ID
  //   return match && match[1];
  // };

  // let videoID;
  // if (data.realisation.frontmatter.youtube){
  //   videoID = getYouTubeVideoId(data.realisation.frontmatter.youtube);
  // }

  return (

    <div className="swiper-gallery-section">

      <div className="description-container">
        {data.title_visible &&
          <Container>
            <AnimationWrapper>
              <TextSection data={data} />
            </AnimationWrapper>
          </Container>
        }
      </div>

      <div className={`swiper-gallery ${data.type}`}>

        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          preloadImages={false}
          lazy={true}
          watchSlidesProgress={true}

          centerInsufficientSlides={true}
          centeredSlides={true}
          centeredSlidesBounds={true}
          autoHeight={true}


          autoplay={{
              delay: 12000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
          }}
          speed={1500}
          pagination={{
              clickable: true,
          }}
          navigation={true}
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
                shadow: true,
                translate: [0, 0, -400],
            },
            next: {
                translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative, Pagination, Navigation, Autoplay]}
          className="homepage-swiper"
        >

            {data.videos.map((photo, key) => (

                <SwiperSlide key={key}>

                  {photo.image &&
                    <GatsbyImage
                      image={photo.image.childImageSharp.gatsbyImageData}
                      alt={photo.title}
                    />
                  }

                  {photo.video &&
                    <video
                      controls
                      autoPlay
                      muted
                      playsInline
                      loop // Optional: Add loop if you want the video to loop
                    >
                      <source src={photo.video.publicURL} type="video/mp4" />
                    </video>
                  }

                  {/* <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${videoID}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                  </iframe> */}

                </SwiperSlide>
            ))}

        </Swiper>

      </div>

    </div>

  )
}

export default SwiperGallery